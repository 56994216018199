import React, { useState } from "react";
import Head from "next/head";
import WidgetPreview from "@portal/components/WidgetPreview";
import CopyCodeArea from "@portal/components/CopyCodeArea";
import styled from "styled-components";
import { getIsRTL } from "@components/RTLProvider";
import { Formik } from "formik";
import _noop from "lodash/noop";
import { Box, Container, Flex, Heading, Img, Text } from "@chakra-ui/react";
import URLField from "@portal/components/URLField";
import WidgetRegularOptions from "@portal/components/fields/WidgetRegularOptions";
import WidgetThemeField from "@portal/components/fields/WidgetThemeField";
import getWidgetPreviewUrl from "@portal/utils/getWidgetPreviewUrl";
import useDefaultUrl from "@portal/hooks/useDefaultUrl";
import getWidgetIframeCode from "@portal/utils/getWidgetIframeCode";
import buildConfig from "build-config.json";

function IndexPage() {
  const defaultUrl = useDefaultUrl();
  const rtl = getIsRTL();

  const [disabledTracklistField, setDisabledTracklistField] = useState(false);

  return (
    <>
      <Head>
        <title>Portal - Deezer</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <PageBackground dir="ltr">
        <Formik
          initialValues={{
            url: defaultUrl,
            theme: "dark",
            width: 700,
            height: 300,
            tracklist: true,
          }}
          onSubmit={_noop}
        >
          {({ values }) => {
            const { url, theme, width, height, tracklist } = values;

            const widgetUrl = getWidgetPreviewUrl({
              theme,
              deezerUrl: url,
              tracklist,
            });

            const iframeCode = getWidgetIframeCode({
              path: widgetUrl,
              width,
              height,
            });

            return (
              <Container
                maxWidth="5xl"
                px={["0", "6"]}
                py={["4", "6"]}
                centerContent
              >
                <Box
                  w="full"
                  py={["6", "14"]}
                  px={["6", "20"]}
                  borderRadius={["none", "lg"]}
                  shadow="md"
                  bg="white"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb="4"
                  >
                    <Heading as="h1" fontWeight="extrabold" fontSize="32px">
                      Widget
                    </Heading>
                    <Img
                      src={`${buildConfig.host_assets_ssl}/images/deezer-logo.svg`}
                      alt="deezer-logo"
                      h="48px"
                      style={{ transform: "translateY(-5px)" }}
                    />
                  </Flex>

                  <Text fontSize="lg">
                    Embed a deezer widget in your website to share your favorite
                    music
                  </Text>

                  <StepTitle>Step 1: Pick your content</StepTitle>

                  <Text>
                    From deezer.com, select any supported content you would like
                    to generate a widget for, copy the URL and paste it in the
                    field below.
                  </Text>

                  <URLField name="url" />

                  <Text fontStyle="italic" fontSize="sm" mt="2">
                    Supported contents: Album, Playlist, Track, Artist, Podcast,
                    Episode
                  </Text>

                  <StepTitle>Step 2: Customize</StepTitle>

                  <Text mb="4">
                    Customize the embed player to your specific needs.
                  </Text>

                  <Text fontWeight="semibold" mb="6">
                    Theme
                  </Text>

                  <WidgetThemeField />

                  <Text fontWeight="semibold" mb="4">
                    Options
                  </Text>

                  <WidgetRegularOptions
                    tracklistDisabled={disabledTracklistField}
                  />

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    my={["8", "8", "12"]}
                    dir={rtl ? "rtl" : "ltr"}
                  >
                    <WidgetPreview
                      key={url}
                      url={widgetUrl}
                      width={
                        (width as string | number) === "100%"
                          ? width
                          : Number(width)
                      }
                      height={Number(height)}
                      onDisableTracklist={setDisabledTracklistField}
                    />
                  </Flex>

                  <StepTitle>
                    Step 3: Get the code and add it to your website
                  </StepTitle>

                  <CopyCodeArea code={iframeCode} />
                </Box>
              </Container>
            );
          }}
        </Formik>
      </PageBackground>
    </>
  );
}

export default IndexPage;

type Props = {
  children: React.ReactNode;
};

const StepTitle: React.FC<Props> = ({ children }) => (
  <Heading as="h3" fontSize="2xl" fontWeight="extrabold" mt="8" mb="2">
    {children}
  </Heading>
);

const PageBackground = styled.div`
  background-color: #a238ff;
`;
